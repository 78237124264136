import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { faqStyles } from './styles';
import { ListingDotBlue } from '../ListingDotBlue/ListingDotBlue';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const ExpandMoreIconnew = styled(ExpandMoreIcon)`
  color: #ea5e2a;
`;
const AccordionPage = ({ location }) => {
  const { t } = useTranslation();
  const classes = faqStyles();

  const [expanded, setExpanded] = React.useState(
    typeof location?.hash === 'string' ? location.hash.slice(1).replace('bh-header', '') : false,
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.faqContainer}>
          <Typography className={classes.contentheading}>{t('trans.faqHead')}</Typography>
        </Box>

        <Box className={classes.subjectbox}>
          <Typography className={classes.subject}>{t('trans.subject1')}</Typography>
        </Box>
        <Accordion className={classes.accordion} expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel10bh-content" id="panel10bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category1')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead10')}</Typography>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent10_1')}

              <Box mt={2}>
                {t('trans.faqContent10_1a')}
                <LinkText to="/prices#pricesplan">
                  <span>{t('trans.faqContent10_2')}</span>
                </LinkText>{' '}
                {t('trans.faqContent10_3')}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion} expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel20bh-content" id="panel20bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category1')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead20')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent20_1')}
              <Box>{t('trans.faqContent20_2')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Box id="panel30" />
        <Accordion className={classes.accordion} expanded={expanded === 'panel30'} onChange={handleChange('panel30')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel30bh-content" id="panel30bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category1')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead30')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent30_1')}
              <Box>{t('trans.faqContent30_2')}</Box>
              <Box mt={2}>
                {t('trans.faqContent30_3a')}
                <LinkText to="/prices#pricesplan">
                  <span>{t('trans.faqContent30_3')}</span>
                </LinkText>{' '}
                {t('trans.faqContent30_4')}
              </Box>
              <Box mt={0}>
                {t('trans.faqContent30_5')}{' '}
                <LinkText to="/calculator#calculator">
                  <span>{t('trans.faqContent30_6')}</span>
                </LinkText>{' '}
                {t('trans.faqContent30_7')}
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Box id="panel35" />
        <Accordion className={classes.accordion} expanded={expanded === 'panel35'} onChange={handleChange('panel35')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel35bh-content" id="panel35bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category1')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead35')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography className={classes.secondaryContent}>
                <Box>{t('trans.faqContent35_1')}</Box>
                <Box mt={2}>{t('trans.faqContent35_2')}</Box>
                <Box mt={2}>
                  <b>{t('trans.faqContent35_3')}</b>
                </Box>
                <Box ml={1} mt={2}>
                  <ListingDotBlue
                    title=""
                    listItems={[
                      t('trans.faqContent35_6'),
                      t('trans.faqContent35_7'),
                      t('trans.faqContent35_8'),
                      t('trans.faqContent35_9'),
                      t('trans.faqContent35_10'),
                    ]}
                  />
                </Box>

                <Box mt={2}>
                  <Typography className={classes.secondaryContent22}>
                    {t('trans.faqContent35_12')}{' '}
                    <LinkText to="/calculator#calculator">
                      <span>{t('trans.faqContent35_12Link')}</span>
                    </LinkText>{' '}
                    {t('trans.faqContent35_13')}
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Box id="panel40" />
        <Accordion className={classes.accordion} expanded={expanded === 'panel40'} onChange={handleChange('panel40')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel40bh-content" id="panel40bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category1')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead40')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>{t('trans.faqContent40_1')}</Typography>
          </AccordionDetails>
        </Accordion>

        {/* -------------------------------------------------------- */}
        <Box ml={1} mt={2} />
        {/* -------------------------------------------------------- */}
        <Box className={classes.subjectbox}>
          <Typography className={classes.subject}>{t('trans.subject2')}</Typography>
        </Box>

        {/* -------------------------------------------------------- */}
        <Accordion className={classes.accordion} expanded={expanded === 'panel60'} onChange={handleChange('panel60')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel60bh-content" id="panel60bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category6')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead60')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>{t('trans.faqContent60_1')}</Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Box id="panel70" />
        <Accordion
          id="panel70"
          className={classes.accordion}
          expanded={expanded === 'panel70'}
          onChange={handleChange('panel70')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel70bh-content" id="panel70bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category6')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead70')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent70_1')}
              <Box mt={2}>
                <b> {t('trans.faqContent70_2')}</b>
              </Box>
              <Box ml={1} mt={2}>
                <ListingDotBlue
                  title=""
                  listItems={[
                    t('trans.faqContent70_3'),
                    t('trans.faqContent70_4'),
                    t('trans.faqContent70_5'),
                    t('trans.faqContent70_6'),
                    t('trans.faqContent70_7'),
                  ]}
                />
              </Box>

              <Box mt={2}>
                <b> {t('trans.faqContent70_8')}</b>
              </Box>
              <Box ml={1} mt={2}>
                <ListingDotBlue
                  title=""
                  listItems={[
                    t('trans.faqContent70_9'),
                    t('trans.faqContent70_10'),
                    t('trans.faqContent70_11'),
                    t('trans.faqContent70_12'),
                    t('trans.faqContent70_13'),
                  ]}
                />
              </Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}

        <Accordion className={classes.accordion} expanded={expanded === 'panel80'} onChange={handleChange('panel80')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel80bh-content" id="panel80bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category6')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead80')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent80_1')}
              <Box mt={2}>{t('trans.faqContent80_2')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Accordion className={classes.accordion} expanded={expanded === 'panel90'} onChange={handleChange('panel90')}>
          <AccordionSummary expandIcon={<ExpandMoreIconnew />} aria-controls="panel90bh-content" id="panel90bh-header">
            <Box>
              <Typography className={classes.category}>{t('trans.category6')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead90')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>{t('trans.faqContent90_1')}</Typography>
          </AccordionDetails>
        </Accordion>

        {/* -------------------------------------------------------- */}
        <Box ml={1} mt={2} />
        <Box className={classes.subjectbox}>
          <Typography className={classes.subject}>{t('trans.subject3')}</Typography>
        </Box>
        {/* -------------------------------------------------------- */}
        <Accordion className={classes.accordion} expanded={expanded === 'panel140'} onChange={handleChange('panel140')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIconnew />}
            aria-controls="panel140bh-content"
            id="panel140bh-header"
          >
            <Box>
              <Typography className={classes.category}>{t('trans.category4')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead140')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent140_1')}
              <Box mt={2}>{t('trans.faqContent140_2')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* -------------------------------------------------------- */}
        <Accordion className={classes.accordion} expanded={expanded === 'panel110'} onChange={handleChange('panel110')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIconnew />}
            aria-controls="panel110bh-content"
            id="panel110bh-header"
          >
            <Box>
              <Typography className={classes.category}>{t('trans.category4')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead110')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent110_1')} <LinkText to="/contact/">{t('trans.faqContent110_2')}</LinkText>{' '}
              {t('trans.faqContent110_3')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Box id="panel120" />
        <Accordion className={classes.accordion} expanded={expanded === 'panel120'} onChange={handleChange('panel120')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIconnew />}
            aria-controls="panel120bh-content"
            id="panel120bh-header"
          >
            <Box>
              <Typography className={classes.category}>{t('trans.category4')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead120')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent120_1')}
              <Box mt={2}>{t('trans.faqContent120_2')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.accordion} expanded={expanded === 'panel150'} onChange={handleChange('panel150')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIconnew />}
            aria-controls="panel150bh-content"
            id="panel150bh-header"
          >
            <Box>
              <Typography className={classes.category}>{t('trans.category4')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead150')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>
              {t('trans.faqContent150_1')}
              <Box mt={2}>{t('trans.faqContent150_2')}</Box>
              <Box mt={2}>{t('trans.faqContent150_3')}</Box>
              <Box mt={2}>{t('trans.faqContent150_4')}</Box>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* -------------------------------------------------------- */}
        {/* -------------------------------------------------------- */}
        <Box ml={1} mt={2} />
        <Box className={classes.subjectbox}>
          <Typography className={classes.subject}>{t('trans.subject6')}</Typography>
        </Box>
        {/* -------------------------------------------------------- */}
        <Accordion className={classes.accordion} expanded={expanded === 'panel100'} onChange={handleChange('panel100')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIconnew />}
            aria-controls="panel100bh-content"
            id="panel100bh-header"
          >
            <Box>
              <Typography className={classes.category}>{t('trans.category4')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead100')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>{t('trans.faqContent100_1')}</Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Accordion className={classes.accordion} expanded={expanded === 'panel160'} onChange={handleChange('panel160')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIconnew />}
            aria-controls="panel160bh-content"
            id="panel160bh-header"
          >
            <Box>
              <Typography className={classes.category}>{t('trans.category4')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead160')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>{t('trans.faqContent160_1')}</Typography>
          </AccordionDetails>
        </Accordion>
        {/* -------------------------------------------------------- */}
        <Accordion className={classes.accordion} expanded={expanded === 'panel170'} onChange={handleChange('panel170')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIconnew />}
            aria-controls="panel170bh-content"
            id="panel170bh-header"
          >
            <Box>
              <Typography className={classes.category}>{t('trans.category4')}</Typography>
              <Typography className={classes.heading}>{t('trans.faqHead170')}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryContent}>{t('trans.faqContent170_1')}</Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default AccordionPage;
