import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const faqStyles = makeStyles(() => ({
  headheadingSite: {
    color: '#080c2d',
    fontSize: '2.8rem',
    fontWeight: 900,
    textAlign: 'center',
    letterSpacing: '4px',
  },
  headheadingContent: {
    color: '#080c2d',
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'center',
    letterSpacing: '0.5px',
  },

  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '50px',
    animation: 'textwelle 0.6s',
  },
  siteTitleContent: {
    fontSize: '1.3rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginLeft: '0px',
    animation: 'textwelle 0.5s',
  },
  category: {
    fontSize: '0.8rem',
    flexBasis: '65%',
    flexShrink: 0,
    color: '#666666',
    fontWeight: '400',
    letterSpacing: '0.03rem',
  },
  subject: {
    color: '#080c2d',
    fontSize: '1.0rem',
    flexBasis: '65%',
    flexShrink: 0,
    fontWeight: '600',
    letterSpacing: '0.03rem',
  },
  heading: {
    fontSize: '1.0rem',
    flexBasis: '65%',
    flexShrink: 0,
    color: '#545372',
    fontWeight: '600',
    letterSpacing: '0.03rem',
  },

  secondaryContent: {
    width: '100%',
    fontSize: '1.0rem',
    color: '#545372',
    backgroundColor: COLOR_WHITE,
    borderTop: '1px solid #cacece',
    borderRadius: '2px',
    padding: '20px 10px 30px 10px ',
    letterSpacing: '0.03rem',
  },

  content_loeschen: {
    fontSize: '1.3rem',
    fontWeight: '600',
    color: '#EFEFEF',
    marginTop: '22px',
  },

  contentheading: {
    fontSize: '1.3rem',
    fontWeight: '600',
    color: '#000038',
    margin: '20px 0px 0px 0px',
    alignItems: 'center',
    display: 'flex',
  },

  faqContainer: {
    backgroundColor: '#EBF0F4',
    height: 'auto',
    padding: '0px 0px 20px 0px',
  },
  container: {
    backgroundColor: '#EBF0F4',
    borderRadius: '4px',
    padding: '0px 0px 50px 0px',
    maxWidth: '1230px',
    margin: '0 auto',
  },
  subjectbox: {
    padding: '20px 0px 10px 3px',
  },

  accordion: {
    color: '#f22121',
    backgroundColor: COLOR_WHITE,
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    margin: '0px 0px 0px 0px ',
  },

  containerFaqFooter: {
    minHeight: '100px',
    maxWidth: '1230px',
    margin: '0 auto',
    marginTop: '-30px',
  },

  contentcontainerFaqFooter: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: '#474646',
    marginTop: '0px',
  },
  boxImagesHead: {
    minHeight: '300px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4em 1em 0em 1em',
  },
}));
