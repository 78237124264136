import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import styled from 'styled-components';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { faqStyles } from './styles';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const ContentContainerFaq = () => {
  const { t } = useTranslation();
  const classes = faqStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <Box className={classes.containerFaqFooter}>
            <Typography className={classes.contentcontainerFaqFooter}>
              {t('trans.faqContainer_1')} <LinkText to="/contact/">{t('trans.faqContainer_2')}</LinkText>{' '}
              {t('trans.faqContainer_3')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentContainerFaq;
