import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import Typography from '@material-ui/core/Typography';
import { Box, Container, Grid } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AccordionPage from './accordionFaq';
import ContentContainerFaq from './contentContainerFaq';
import { faqStyles } from './styles';

const FAQBridged = ({ location }) => {
  const { backgroundImageFAQ } = useStaticQuery(
    graphql`
      query {
        backgroundImageFAQ: file(relativePath: { eq: "library.png" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );

  const pluginImage = getImage(backgroundImageFAQ);
  const { t } = useTranslation();
  const classes = faqStyles();
  return (
    <>
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.boxImagesHead}>
              <Typography className={classes.siteTitle}>{t('trans.faqImagesHead')}</Typography>
              <Box mt={1.5}>
                <Typography className={classes.siteTitleContent}>{t('trans.faqImagesContentOne')}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BgImage>

      <Container>
        <Box mt={9}>
          <Typography className={classes.headheadingSite}>{t('trans.faq')}</Typography>
          <Typography className={classes.headheadingContent}>{t('trans.faqContent')}</Typography>
        </Box>
      </Container>

      <Box mt={5} className="masthead2 mastheadlightblue">
        <Grid container spacing={3} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <AccordionPage location={location} />
          </Grid>
        </Grid>
      </Box>

      <Box className="masthead2">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box>
              <ContentContainerFaq />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FAQBridged;
